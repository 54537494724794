// @ts-nocheck
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DatePicker, InputAmount, Checkbox, notification } from '@gamesb42/ui-kit';
import { Modal, Input, Form } from 'antd';
import moment from 'moment';

import { DropdownKit, DropdownSizeEnum } from 'components/uiKit/DropdownKit';
import urls from 'constants/urls';
import endpoints from 'consts/endpoints';
import { getToken } from 'helpers/token';
import { downloadFile } from 'helpers/file';
import { deleteActivationOrderDataType } from 'queries/activationOrder/deleteActivationOrder';
import useAlert from 'hooks/api/useAlert';
import useActivationOrder, { activationOrderDataType, AoTypeEnum } from 'hooks/api/useActivationOrder';
import ViewGeneralActions from 'components/common/ViewGeneralActions';
import ConfirmationDeleteModal from 'components/common/ConfirmationDeleteModal';
import pencilSvg from 'assets/img/newPencil.svg';
import downloadSvg from 'assets/img/newDownload.svg';
import delSvg from 'assets/img/newDelete.svg';
import useErrorCallback from 'hooks/useErrorCallback';
import reloadSvg from 'assets/img/reload.svg';
import ChangeStatusModal from 'components/modal/ChangeStatusModal';
import { AOStatus, CompanyStatus } from 'components/common/Status';
import { changeStatusAO, changeStatusAONew } from 'api/ao';
import { getCompanyById } from 'api/company';
import ChangeAOStatusModal from 'components/modal/ChangeAOStatusModal';
import CreateTransactionPopUp from '../../PopUps/CreateTransactionPopUp/CreateTransactionPopUp';

import s from './style.module.scss';
import FactorAccountsSelect from 'components/FactorAccountsSelect';
import useCompany, { useCompanyType } from 'hooks/api/useCompany';

type PropsT = {
  type: AoTypeEnum;
  id: activationOrderDataType['id'];
  deleteActivationOrder: (data: deleteActivationOrderDataType) => void;
  title: string;
  companyId?: string;
  setStatus?: any;
  currency: string;
  amount: string;
};

const ActionsButton: FC<PropsT> = ({
  id,
  deleteActivationOrder,
  type,
  title,
  companyId,
  setStatus,
  currency,
  amount,
}) => {
  const { setAlert } = useAlert();
  const [isDeleted, setIsDeleted] = useState(false);
  const { getAlert } = useErrorCallback();
  const history = useHistory();
  const [isOpenChangeStatusModal, setIsOpenChangeStatusModal] = useState(false);
  const { getActivationOrder, activationOrderData } = useActivationOrder();
  //@ts-ignore
  const { activationOrderId } = useParams();
  const [tax, setTax] = useState();
  const [isOpenCreateTransaction, setIsOpenCreateTransaction] = useState(false);
  const {
    companiesData: { names = [] },
    companiesData,
    aoFormData,
    setAOFormData,
    listSwitch,
    setIsSwitchList,
    getCompanies,
  }: useCompanyType = useCompany();
  const [same, setSame] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [transactionData, setTransactionDate] = useState({
    recipient_company_id: companyId,
    sender_account: '',
    sender_bank_name: '',
    sender_swift: '',
    tx_date: '',
    newStatus: 'funded',
  });
  const [form] = Form.useForm();

  const clearErrors = () => {
    form.setFields([
      { name: 'tx_date', errors: [] },
      { name: 'bank_account', errors: [] },
    ]);
  };

  const createTransaction = async () => {
    try {
      const result = await form.validateFields();
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${getToken()}`);

      const requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
      };

      fetch(
        `${process.env.REACT_APP_BASE_URL}/activation_order/${id}/status?recipient_company_id=${companyId}&sender_account=${transactionData.sender_account}&sender_bank_name=${transactionData.sender_bank_name}&sender_swift=${transactionData.sender_swift}&tx_date=${transactionData.tx_date}&newStatus=funded`,
        requestOptions,
      )
        .then((response) => response.json())
        .then((result) => {
          if (Object.hasOwn(result, 'detail')) {
            notification.error({ message: result.detail, theme: 'dark', placement: 'bottomLeft' });
          } else {
            notification.success({
              message: 'The AO status has been changed successfully',
              theme: 'dark',
              placement: 'bottomLeft',
            });
          }

          setStatus(result.status);
          getActivationOrder({ id: activationOrderId });
          setModalVisible(false);
        })
        .catch(() => {});
    } catch (e) {}
  };

  useEffect(() => {
    if (companyId) getCompanyById(companyId).then((company: any) => setTax(company?.tax_treaty));
  }, []);

  // console.log('-->', aoFormData);

  return (
    <div style={{ position: 'absolute', top: -83, right: 0 }}>
      <ViewGeneralActions
        buttonList={[
          {
            image: pencilSvg,
            title: 'forms.activationOrder.editAo',
            style: { color: '#838998' },
            onClick: () => history.push(`${urls.getActivationOrder()}/edit/${type}/${id}`),
          },
          {
            image: reloadSvg,
            title: 'company.changeStatus',
            style: { color: '#838998' },
            onClick: () => setIsOpenChangeStatusModal(true),
          },
          {
            image: delSvg,
            title: 'forms.activationOrder.deleteAo',
            style: { color: '#EC3E72' },
            onClick: () => setIsDeleted(true),
          },
        ]}
      />
      <ConfirmationDeleteModal
        isOpen={isDeleted}
        title={title}
        onClose={() => setIsDeleted(false)}
        handleConfirmClick={() => {
          deleteActivationOrder({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            id,
            successCallback: () => {
              history.push(urls.getActivationOrder());
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            errorCallback: (error) => {
              setAlert({
                type: 'error',
                message: error.body.description,
              });
            },
          });
        }}
      />
      {isOpenChangeStatusModal && (
        <ChangeAOStatusModal
          tax={tax}
          isCompany={false}
          currentStatus={activationOrderData.status}
          isOpen={isOpenChangeStatusModal}
          onClose={() => setIsOpenChangeStatusModal(false)}
          handleConfirmClick={(status) => {
            setIsOpenChangeStatusModal(false);

            if (activationOrderData.status === 'ACTIVE') {
              clearErrors();
              setModalVisible(true);
              return;
            }

            // @ts-ignore
            changeStatusAONew(activationOrderId, status as AOStatus)
              .then((result) => {
                setStatus(result);
                if (Object.hasOwn(result, 'detail')) {
                  notification.error({ message: result.detail, theme: 'dark', placement: 'bottomLeft' });
                } else {
                  notification.success({
                    message: 'The AO status has been changed successfully',
                    theme: 'dark',
                    placement: 'bottomLeft',
                  });
                }
              })
              .catch(console.error);
          }}
        />
      )}
      <Modal
        className={s.createT}
        title="Create transaction"
        visible={modalVisible}
        okText="Create transaction"
        onOk={() => createTransaction()}
        onCancel={() => setModalVisible(false)}
        centered
      >
        <Form form={form} className={s.form}>
          <div className={s.row}>
            <div className={s.classFullWidth}>
              <Form.Item name="tx_date" rules={[{ required: true, message: 'Please input date' }]}>
                <DatePicker
                  onChange={(e) => {
                    if (e) {
                      setTransactionDate({ ...transactionData, tx_date: e.format('YYYY-MM-DD') });
                    } else {
                      setTransactionDate({ ...transactionData, tx_date: '' });
                    }
                  }}
                  label="Date"
                  size="large"
                  format="DD.MM.YYYY"
                  aria-required
                />
              </Form.Item>
            </div>

            <div className={s.classFullWidth}>
              <InputAmount value={amount} label="Amount" currency={currency} size="large" disabled />
            </div>
          </div>
          <Form.Item name="bank_account" rules={[{ required: true, message: 'Please input factor bank account' }]}>
            <FactorAccountsSelect
              needSetDefaultValue
              currency="USD"
              onChange={(account, { source: { bank_name, swift } }) => {
                setTransactionDate({
                  ...transactionData,
                  sender_account: account,
                  sender_bank_name: bank_name,
                  sender_swift: swift,
                });
              }}
              required
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            />
          </Form.Item>
          <Checkbox
            style={{ marginBottom: '32px' }}
            checked={same}
            onChange={(e) => {
              names.length === 0 && getCompanies({ showDeleted: false });
              setSame(e.target.checked);
              if (e.target.checked) {
                setTransactionDate({ ...transactionData, recipient_company_id: aoFormData.company_id });
              } else {
                setTransactionDate({ ...transactionData, recipient_company_id: '' });
              }
            }}
          >
            Recipient is the same as Client
          </Checkbox>

          {!same && (
            <DropdownKit
              label="Recipient"
              options={names}
              keyObj="name"
              value=""
              value={names.find((val) => val.id === aoFormData.recipient) || ''}
              changeSelectItem={(_, data) => {
                // console.log(_, data);
                setTransactionDate({ ...transactionData, recipient_company_id: data.id });
              }}
              positionType={DropdownSizeEnum.SIZE_60}
            />
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default ActionsButton;
